@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.stickyHeader {
  background-color: colors.$primary-color;
  color: white;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
  transition: transform 0.2s linear;

  .hamburger {
    grid-area: menu;
  }
  .searchbar {
    grid-area: searchbar;
    padding: 0;
  }
  .logo {
    grid-area: logo;
  }
  .headerMenuItems {
    grid-area: hmi;
  }

  @media screen and (min-width: vars.$extra-large) {
    color: colors.$default-text;
    background-color: colors.$background;
    border-bottom: 1px solid colors.$grey;
  }
}

.stickyHeaderFixed {
  position: fixed;
}

.stickyHeaderMarketplace {
  background-color: colors.$primary-color;
  color: white;
}

.stickyHeaderContainer {
  display: grid;
  grid-template-columns: 24px auto max-content;
  grid-template-areas:
    'menu logo hmi'
    'searchbar searchbar searchbar';
  gap: vars.$spacingL;
  padding: 24px 0;
  box-sizing: content-box;
  opacity: 1;
  position: relative;

  @media screen and (min-width: vars.$medium) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (min-width: vars.$extra-large) {
    column-gap: vars.$gutter;
    grid-template-areas: 'logo searchbar hmi';
    grid-template-columns: max-content auto max-content;
    max-height: 100px;
  }
}

.hide {
  transform: translateY(-100%);
  @media screen and (min-width: vars.$medium) {
    transform: translateY(-70px);
  }
  @media screen and (min-width: vars.$extra-large) {
    transform: translateY(0);
  }
}

.alignStickyHeader {
  align-items: center;
}

.hamburger {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  color: inherit;
  width: fit-content;

  @media screen and (min-width: vars.$extra-large) {
    display: none;
  }
}

.searchbar {
  padding: 15px 0 0 0;
  @media screen and (min-width: vars.$extra-large) {
    padding: 0;
  }
}

.logo {
  display: flex;
  justify-content: flex-start;

  @media screen and (min-width: vars.$medium) {
    justify-content: center;
  }
  @media screen and (min-width: vars.$extra-large) {
    justify-content: flex-start;
  }

  picture {
    display: flex;
  }
}

.logo img,
.logo .logoSvg {
  width: 110px;
  @media screen and (min-width: vars.$medium) {
    width: 118px;
    height: 36px;
  }
  @media screen and (min-width: vars.$extra-large) {
    width: 200px;
    height: 60px;
  }
}

.logoSvg {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    @media screen and (min-width: vars.$medium) {
      transform: scale(1.1);
    }

    @media screen and (min-width: vars.$extra-large) {
      transform: scale(1.8);
    }
  }
}

.dailies {
  display: none;

  @media screen and (min-width: vars.$extra-large) {
    align-items: center;
    display: flex;
  }
}

.dailies p {
  margin-right: 15px;
}

.noDailies {
  justify-content: flex-start;
}

.alignHeader {
  align-items: center;
}

.activeFiltersContainer {
  display: flex;
  flex: 0 0 auto;
  margin: 15px 0;
}

.activeFilters {
  display: flex;
  flex: 0 1 auto;
  flex-flow: row wrap;
  list-style-type: none;
}

.activeFilterPill {
  align-items: center;
  background-color: colors.$primary-color;
  border-radius: 4px;
  color: colors.$white;
  cursor: pointer;
  display: flex;
  font-size: 0.813rem;
  height: 25px;
  justify-content: space-between;
  line-height: 1.563rem;
  margin: 0 5px 5px 0;
  min-width: 0;
  padding: 0 10px;
  text-decoration: none;

  &Text {
    font-size: 0.87rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &Cross {
    align-items: center;
    color: colors.$white;
    display: flex;
    font-size: 1.7rem;
    height: 100%;
    margin-left: 10px;
    text-decoration: none;
  }
}

.clearAllFilters {
  align-items: center;
  border-left: solid 1px colors.$grey;
  display: flex;
  flex: 0 0 auto;
  margin-left: 10px;
  padding-left: 10px;
}

.clearAllFiltersCrossText {
  display: none;
  font-size: 0.87rem;
  @media screen and (min-width: vars.$medium) {
    display: inline-block;
  }
}

.clearAllFiltersCross {
  align-items: center;
  display: flex;
  color: colors.$secondary-text;
  text-decoration: none;
}

.clearAllFiltersCrossIcon {
  align-items: center;
  background: colors.$secondary-text;
  border-radius: 50%;
  color: colors.$white;
  display: flex;
  flex: 0 0 auto;
  font-size: 1.1rem;
  height: 18px;
  justify-content: center;
  margin-right: 5px;
  width: 18px;
}
