@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.root {
  row-gap: vars.$gutter;

  &.viewOptionTiles {
    @media screen and (min-width: vars.$extra-extra-large) {
      grid-template-columns: repeat(15, 1fr);
    }
  }
}
