@use '../../styleguide/vars.scss';
.videoCard {
  display: block;
  background: #000;
  padding: 0;
  width: 100%;
  max-height: 100%;
  overflow: hidden;

  &Overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: opacity 0.2s ease-in;
    opacity: 1;

    svg {
      transition: transform 0.2s ease-in;
    }
  }

  &OverlayHidden {
    opacity: 0;

    svg {
      transform: scale(1.5);
    }
  }

  &Close {
    display: none;
  }

  &Wrapper {
    height: 100%;
  }

  &Text {
    position: absolute;
    bottom: 0;
    padding: 15px;
    background: linear-gradient(0deg, #4a4a4a, transparent);
    width: 100%;
    color: #fff;
    h3 {
      font-size: 18px;
      line-height: normal;
    }
    p {
      font-size: 14px;
    }
  }

  &Detached {
    background: #000;
    width: 300px;
    height: auto;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
    animation-name: slideUp;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;

    @media screen and (min-width: vars.$medium) {
      right: 0;
      left: initial;
      width: 100%;
    }

    .videoCardClose {
      display: inline-block;
      position: absolute;
      background: #4a4a4a;
      color: white;
      border-radius: 50%;
      right: -15px;
      top: -15px;
      font-size: 20px;
      line-height: 30px;
      width: 30px;
      vertical-align: middle;
      text-align: center;
      z-index: 3;

      @media screen and (min-width: vars.$medium) {
        right: initial;
        left: -10px;
        top: -10px;
        font-size: 15px;
        line-height: 20px;
        width: 20px;
      }
    }
  }

  &Tile {
    grid-area: 1 / 1 / span 1 / span 12;
  }

  &Column {
    grid-area: 1 / 1 / span 1 / span 12;
  }
}

@media screen and (min-width: vars.$medium) {
  .videoCard {
    &Detached {
      width: 600px;
      right: 15px;
      bottom: 90px;
    }

    &Tile {
      grid-area: 1 / 5 / span 1 / span 8;
    }

    &Column {
      grid-area: 1 / 1 / span 1 / span 12;
    }
  }
}
@media screen and (min-width: vars.$large) {
  .videoCard {
    &Column {
      grid-area: 1 / 7 / span 1 / span 6;
    }
  }
}
@media screen and (min-width: vars.$extra-extra-large) {
  .videoCard {
    &Tile {
      grid-area: 1 / 10 / span 1 / span 6;
    }
  }
}

@keyframes slideUp {
  from {
    transform: translateY(300px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
